export const parseInit = (env) => {
    if (env === "dev") 
    return (
        {
        SERVER_URL        : "http://127.0.0.1:1337/parse/",
        APPLICATION_ID    : "GROONZY",
        }
    )
    else if (env === "prod") return (
        {
        SERVER_URL      : "https://textopia.b4a.app/",
        APPLICATION_ID  : "6AJfa2enUPtiqpzcGWzeGenVVUMeI9JUkxCuz5H8",
        REST_API_KEY    : "O5qTANiMOtyApGNQFr17sb3xnj9wiXNSza4ZkqR7",
        JAVASCRIPT_KEY  : "HyqMpblsidWCv10X2cUvcHavsblCldY45log2cmW",
        }
    )
}