import React, {useState} from 'react';
import TinyFlagReact from 'tiny-flag-react';
import PhoneFormatter from 'react-headless-phone-input';

import logo from './logo.svg';
import close from './assets/clear-button.svg';
import {ReactComponent as DefaultLauncher} from './assets/Chat-smile.svg';
import defaultAvatar from './assets/avatar.jpg';
import refresh from './assets/refresh.svg';
import * as envConfig from "./config.js";
//import './normalize.css';
import './App.scss';

const parseConfig = envConfig.parseInit("prod");

function App({data}) { 

  const [showChat, setShowChat] = useState(false);  
 
  function getScrollHeight(elm){
    var savedValue = elm.value
    elm.value = ''
    elm._baseScrollHeight = elm.scrollHeight
    elm.value = savedValue
  }

  function onExpandableTextareaInput({ target:elm }){
    // make sure the input event originated from a textarea and it's desired to be auto-expandable
    if( !(elm.classList.contains('rcw-messages-autoExpand')) || !(elm.nodeName == 'TEXTAREA') ) return

    var minRows = elm.getAttribute('data-min-rows')|0, rows;
    !elm._baseScrollHeight && getScrollHeight(elm)

    elm.rows = minRows
    rows = Math.ceil((elm.scrollHeight - elm._baseScrollHeight) / 20)
    elm.rows = minRows + rows
  }

  
 
  //--> lookup any customization details
  let config = data.webchatConfig;
  let primaryColor = config && config.primaryColor ? config.primaryColor : "#663399";
  let title = config && config.title ? config.title : "We'll text you";
  let invitation = config && config.invitation ? config.invitation : "Enter your information, and our team will text you shortly.";
  let country = config && config.country ? config.country : 'US';
  let showAvatar = config && (config.showAvatar === false) ? false : true;
  let customAvatar = config && config.customAvatar && config.customAvatar.url ? config.customAvatar.url : '';
  let stockAvatar = config && config.stockAvatar ? require('./assets/' + config.stockAvatar).default : '';
  //let customIcon = config && config.customIcon && config.customIcon.url ? config.customIcon.url : '';
  //let stockIcon = config && config.stockIcon ? require('./assets/' + config.stockIcon).default : ''; 

  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const [msg, setMsg] = useState('');
  const [msgError, setMsgError] = useState('');

  const [submissionError, setSubmissionError] = useState('');

  const [submitting, setSubmitting] = useState(false);
  const [msgSubmitted, setMsgSubmitted] = useState(false);
  const [replyPhone, setReplyPhone] = useState('');
  const [replyAddress, setReplyAddress] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
   
    if (!name || name === "")  {
      setNameError("Required");
      return;
    }
    else setNameError('');

    if (!phone || phone === "") {
      setPhoneError("Required");
      return;
    }  
    //else if (!isValidPhone) {
    //  setPhoneError("Check phone number");
    //  return;
    //}  
    else {
      setPhoneError('');
    }

    if (!msg || msg === "") {
      setMsgError("Required");
      return;
    }
    else setMsgError('');


    //--> the checks above are passed, so now start the submitting process

    setSubmitting(true);

    const a = require('axios');

    let reqObj = {
      name: name,
      phone: phone,
      msg: msg,
      tenant: data && data.objectId,
    }


    let httpObj = {
      url: parseConfig.SERVER_URL + "functions/submitWebchatMsg", 
      method: 'post',
      headers: {"X-Parse-Application-Id": parseConfig.APPLICATION_ID, "X-Parse-REST-API-Key": parseConfig.REST_API_KEY, "Content-Type": "application/json"},
      data: reqObj,
    }

    let res = await a(httpObj);
    let myData = res.data && res.data.result;
    
    setSubmitting(false);

    if (myData) {
        setReplyPhone(myData.replyPhone);
        setReplyAddress(myData.replyAddress);
        setMsgSubmitted(true);  
    }
    else {
        setSubmissionError(true);
    }
  }

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '')
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
    }
    return null
  }

  const [isValidPhone, setIsValidPhone] = useState('');

  return (

        <div>
          <div className={`rcw-widget-container ${window.screen.width < 800 && "rcw-full-screen"}`}>
            {showChat &&
            <>
              <div className={`rcw-conversation-container ${showChat ? "active" : "hidden"} ${window.outerWidth < 800 && "rcw-full-screen"}`} aria-live="polite">
                
                <div className={`rcw-header`} style={{backgroundColor: primaryColor}}>
                  <span className="rcw-title">                    
                    <span className={`rcw-header-icon ${!config ? "display-none" : (config && !config.showAvatar) ? "" : "display-none"} `} style={{opacity: 0.8}}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                              <rect x="0" y="0" width="24" height="24"/>
                              <polygon fill="#FFFFFF" opacity="1" points="5 15 3 21.5 9.5 19.5"/>
                              <path d="M13,2 C18.5228475,2 23,6.4771525 23,12 C23,17.5228475 18.5228475,22 13,22 C7.4771525,22 3,17.5228475 3,12 C3,6.4771525 7.4771525,2 13,2 Z M7.16794971,13.5547002 C8.67758127,15.8191475 10.6456687,17 13,17 C15.3543313,17 17.3224187,15.8191475 18.8320503,13.5547002 C19.1384028,13.0951715 19.0142289,12.4743022 18.5547002,12.1679497 C18.0951715,11.8615972 17.4743022,11.9857711 17.1679497,12.4452998 C16.0109146,14.1808525 14.6456687,15 13,15 C11.3543313,15 9.9890854,14.1808525 8.83205029,12.4452998 C8.52569784,11.9857711 7.90482849,11.8615972 7.4452998,12.1679497 C6.98577112,12.4743022 6.86159725,13.0951715 7.16794971,13.5547002 Z" fill="#FFFFFF"/>
                          </g>
                      </svg>
                    </span>                                   
                    {title}
                  </span>
                </div>

                <div id="messages" className="rcw-messages-container">
                  <form noValidate>
                  <>

                    <div className="">
                      <div className="rcw-messages-blurb" style={{marginTop: !config || (config && config.showAvatar) ? "36px" : "16px"}}>
                       {(!config || (config && config.showAvatar)) && <img src={customAvatar ? customAvatar : stockAvatar ? stockAvatar : defaultAvatar} className="avatar" alt="Agent" />}    
                        <div>{invitation}</div>
                      </div>

                      {!msgSubmitted && 
                        <div className="rcw-messages-form-content" style={{padding: '16px 24px 20px'}}>   

                          <div className="rcw-messages-group">      
                            <input className="rcw-messages-input" 
                                   type="text" 
                                   required 
                                   onFocus={() => setActive1(true)} 
                                   onBlur={() => setActive1(false)} 
                                   value={name} 
                                   onChange={(e) => setName(e.target.value)}
                                   />
                            <span className="rcw-messages-highlight"></span>
                            <span className="rcw-bar-before" style={{backgroundColor: primaryColor}}></span>
                            <span className="rcw-bar-after" style={{backgroundColor: primaryColor}}></span>
                            <label className="rcw-messages-label" style={active1 ? {color: primaryColor} : {}}>Name</label>

                            {name && name !== "" &&
                              <div>
                                <svg className="rcw-messages-checkmark" 
                                     viewBox="0 0 52 52" 
                                     style={{width: '22px', height: '22px', borderRadius: '50%', display: 'block', strokeWidth: 5, stroke: `${primaryColor}`, strokeMiterlimit: 10, boxShadow: 'rgb(34, 216, 93) 0px 0px 0px inset'}}>
                                     <path fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" style={{transformOrigin: '50% 50%', strokeDasharray: 48, strokeDashoffset: 48, animation: '0.4s cubic-bezier(0.65, 0, 0.45, 1) 0s 1 normal forwards running stroke'}}></path>
                                </svg>
                              </div>
                            }
                            {nameError && (!name || name === "") && <span className="rcw-form-error">{nameError}</span>}
                          </div>

                          <div  className="rcw-messages-group">
                            <PhoneFormatter defaultCountry="US" value={phone} onChange={setPhone}>
                              {({ country, impossible, onBlur, onInputChange, inputValue }) => {

                                return (
                                    <>
                                    <div className="">
                                      <span className="rcw-messages-flag">
                                        {country ? (
                                          <TinyFlagReact
                                            country={country}
                                            alt={country + " flag"}
                                            fallbackImageURL={`https://cdn.jsdelivr.net/npm/react-flagkit@1.0.2/img/SVG/${country}.svg`}
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </span>
                                      <input className="rcw-messages-input" 
                                         type="text"   
                                         required                                   
                                         value={inputValue}
                                         onFocus={() => setActive2(true)} 
                                         onBlur={() => {setActive2(false); onBlur()}}
                                         onChange={e => {onInputChange(e.target.value); e.target.value !== "" && setPhoneError('')}}
                                         /> 
                                      <span className="rcw-messages-highlight"></span>
                                      <span className="rcw-bar-before" style={{backgroundColor: primaryColor}}></span>
                                      <span className="rcw-bar-after" style={{backgroundColor: primaryColor}}></span>
                                      <label className="rcw-messages-label" style={active2 ? {color: primaryColor} : {}}>Mobile Phone</label>                                       
                                      {( phoneError ) && <span className="rcw-form-error">{phoneError}</span>}  
                                      {( impossible ) && <span className="rcw-form-error">Check phone number</span>}  
                                    </div> 
                                    
                                    {phone && phone !== "" && !impossible &&
                                      <div>
                                        <svg className="rcw-messages-flag-check" 
                                             viewBox="0 0 52 52" 
                                             style={{width: '22px', height: '22px', borderRadius: '50%', display: 'block', strokeWidth: 5, stroke: `${primaryColor}`, strokeMiterlimit: 10, boxShadow: 'rgb(34, 216, 93) 0px 0px 0px inset'}}>
                                             <path fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" style={{transformOrigin: '50% 50%', strokeDasharray: 48, strokeDashoffset: 48, animation: '0.4s cubic-bezier(0.65, 0, 0.45, 1) 0s 1 normal forwards running stroke'}}></path>
                                        </svg>
                                      </div>
                                    } 
                                    </>                              
                                )
                              }}
                            </PhoneFormatter>
                            
                          </div>          

                          <div className="rcw-messages-group">      
                            <textarea className="rcw-messages-textarea rcw-messages-autoExpand" 
                                      data-min-rows={1} 
                                      required 
                                      rows={1} 
                                      onChange={(e) => {onExpandableTextareaInput(e); setMsg(e.target.value)}} 
                                      onFocus={() => setActive3(true)} 
                                      onBlur={() => setActive3(false)}
                                      value={msg}
                                      ></textarea>
                            <span className="rcw-messages-highlight"></span>
                            <span className="rcw-bar-before" style={{backgroundColor: primaryColor}}></span>
                            <span className="rcw-bar-after" style={{backgroundColor: primaryColor}}></span>
                            <label className="rcw-messages-label" style={active3 ? {color: primaryColor} : {}}>Message</label>
                            {msg && msg !== "" &&
                              <div>
                                <svg className="rcw-messages-checkmark" 
                                     viewBox="0 0 52 52" 
                                     style={{width: '22px', height: '22px', borderRadius: '50%', display: 'block', strokeWidth: 5, stroke: `${primaryColor}`, strokeMiterlimit: 10, boxShadow: 'rgb(34, 216, 93) 0px 0px 0px inset'}}>
                                     <path fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" style={{transformOrigin: '50% 50%', strokeDasharray: 48, strokeDashoffset: 48, animation: '0.4s cubic-bezier(0.65, 0, 0.45, 1) 0s 1 normal forwards running stroke'}}></path>
                                </svg>
                              </div>
                            }
                            {msgError && (!msg || msg === "") && <span className="rcw-form-error">{msgError}</span>}
                          </div>   

                        </div>    
                      }

                      {msgSubmitted && !submissionError &&
                      <>
                        <div className="rcw-messages-submitted-content" style={{padding: '16px 24px 20px'}}>   
                          <div className="rcw-messages-submitted-text rcw-messages-submitted-phone">{formatPhoneNumber(phone.slice(2))}</div>
                          <div className="rcw-messages-submitted-text rce-messages-submitted-msg">{msg}</div>                          
                        </div>
                        <div className="rcw-messages-submitted-received">
                          <svg
                                   viewBox="0 0 52 52" 
                                   style={{marginTop: '-5px', width: '22px', height: '22px', borderRadius: '50%', display: 'block', strokeWidth: 5, stroke: `${primaryColor}`, strokeMiterlimit: 10, boxShadow: 'rgb(34, 216, 93) 0px 0px 0px inset'}}>
                                   <path fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" style={{transformOrigin: '50% 50%', strokeDasharray: 48, strokeDashoffset: 48, animation: '0.4s cubic-bezier(0.65, 0, 0.45, 1) 0s 1 normal forwards running stroke'}}></path>
                          </svg>
                          Received
                        </div>
                      </>
                      }

                      {msgSubmitted && submissionError &&
                      <>
                        <div className="rcw-messages-submitted-content" style={{padding: '16px 24px 20px'}}>   
                          <div className="rcw-messages-submitted-text rcw-messages-submitted-phone">{formatPhoneNumber(phone.slice(2))}</div>
                          <div className="rcw-messages-submitted-text rce-messages-submitted-msg" style={{color: 'red'}}>Message Error!</div>                          
                        </div>                        
                      </>
                      }
                          
                    </div> 

                    
                      <div className={`rcw-messages-submission ${msgSubmitted && "rcw-messages-submission-leave"}`}>
                      {!msgSubmitted && !submissionError &&
                      <> 
                        <div className="">
                          <p>By submitting, you agree to receive text from us.  SMS/Data rates may apply.</p>
                        </div>
                        <div className="rcw-messages-button-container">
                          <button type="submit" 
                                  className={`rcw-messages-submit-button ${submitting ? "rcw-button-disabled" : ""} `}
                                  tabIndex={6} 
                                  style={{backgroundColor: primaryColor}} 
                                  onClick={(e) => handleSubmit(e)}
                                  >
                            <div className="rcw-submit-button-text">Send
                              {submitting && <img src={refresh} className="rcw-submit-button-icon rcw-rotate-submit"/>}
                            </div>
                          </button>
                          {window.screen.width < 800 && 
                          <button type="submit" className={`rcw-messages-cancel-button ${submitting ? "rcw-button-disabled" : ""} `} tabIndex={7}>
                            <div onClick={() => {
                                    setShowChat(false);
                                    window.parent.postMessage("||--caiwebchatEvent--||" + JSON.stringify({ action: 'hide chat' }), '*');
                                 }}>Close</div>
                          </button>
                          }
                        </div>
                      </>
                      }
                      </div> 
                     

                    {msgSubmitted && 
                      <div className="rcw-messages-conf" style={{marginTop: "16px"}}>
                          <div className="rcw-messages-conf-header">We got your message.</div>
                          <div className="rcw-messages-conf-content">Our team will text you back from the number {/*A team member from our location at {replyAddress} will text you back from the number*/}</div>
                          <div className="rcw-messages-conf-phone">{formatPhoneNumber(replyPhone.slice(2))}</div>
                          <div className="rcw-messages-conf-checkmark">
                            <svg className=""
                                 viewBox="0 0 52 52" 
                                 style={{width: '22px', height: '22px', borderRadius: '50%', display: 'block', strokeWidth: 5, stroke: `${primaryColor}`, strokeMiterlimit: 10, boxShadow: 'rgb(34, 216, 93) 0px 0px 0px inset'}}>
                                 <path fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" style={{transformOrigin: '50% 50%', strokeDasharray: 48, strokeDashoffset: 48, animation: '0.4s cubic-bezier(0.65, 0, 0.45, 1) 0s 1 normal forwards running stroke'}}></path>
                            </svg>
                          </div>

                         
                      </div>                      
                    }

                    {msgSubmitted &&
                    <div className="rcw-messages-button-container">
                        <button type="submit" style={{marginTop: "16px"}} className={`rcw-messages-cancel-button ${submitting ? "rcw-button-disabled" : ""} `} tabIndex={7}>
                            <div onClick={() => {
                                    setShowChat(false);
                                    window.parent.postMessage("||--caiwebchatEvent--||" + JSON.stringify({ action: 'hide chat' }), '*');
                                 }}>Close</div>
                        </button>
                    </div>
                    }
                    

               </>
               </form>
               </div>
              
               <div className="rcw-sender rcw-powered-by">
                 <div className="terms">Powered By <b>Textopia</b></div>
               </div>
                
              </div>
            </>

            }
            
              <button type="button" 
                      className={`rcw-launcher ${(window.screen.width < 800) && showChat && "rcw-hide-sm"} `} 
                      onClick={() => {
                        setShowChat(!showChat); 
                        if (!showChat) window.parent.postMessage("||--caiwebchatEvent--||" + JSON.stringify({ action: 'show chat' }), '*');
                        else if (showChat) window.parent.postMessage("||--caiwebchatEvent--||" + JSON.stringify({ action: 'hide chat' }), '*');
                      }} 
                      style={{backgroundColor: primaryColor}}>      
              {showChat ?
                <img src={close} className="rcw-close-launcher" alt="Open" /> :
                <svg className="rcw-open-launcher" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <rect x="0" y="0" width="42" height="42"/>
                        <polygon fill="#FFFFFF" opacity="1" points="5 15 3 21.5 9.5 19.5"/>
                        <path d="M13,2 C18.5228475,2 23,6.4771525 23,12 C23,17.5228475 18.5228475,22 13,22 C7.4771525,22 3,17.5228475 3,12 C3,6.4771525 7.4771525,2 13,2 Z M7.16794971,13.5547002 C8.67758127,15.8191475 10.6456687,17 13,17 C15.3543313,17 17.3224187,15.8191475 18.8320503,13.5547002 C19.1384028,13.0951715 19.0142289,12.4743022 18.5547002,12.1679497 C18.0951715,11.8615972 17.4743022,11.9857711 17.1679497,12.4452998 C16.0109146,14.1808525 14.6456687,15 13,15 C11.3543313,15 9.9890854,14.1808525 8.83205029,12.4452998 C8.52569784,11.9857711 7.90482849,11.8615972 7.4452998,12.1679497 C6.98577112,12.4743022 6.86159725,13.0951715 7.16794971,13.5547002 Z" fill="#FFFFFF"/>
                    </g>
                </svg>
                
              }
            </button>
          </div>
        </div>

  );
}

export default App;
