import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as envConfig from "./config.js";

const parseConfig = envConfig.parseInit("prod");

function parseUri (str) {
	var	o   = parseUri.options,
		m   = o.parser[o.strictMode ? "strict" : "loose"].exec(str),
		uri = {},
		i   = 14;

	while (i--) uri[o.key[i]] = m[i] || "";

	uri[o.q.name] = {};
	uri[o.key[12]].replace(o.q.parser, function ($0, $1, $2) {
		if ($1) uri[o.q.name][$1] = $2;
	});

	return uri;
};

parseUri.options = {
	strictMode: false,
	key: ["source","protocol","authority","userInfo","user","password","host","port","relative","path","directory","file","query","anchor"],
	q:   {
		name:   "queryKey",
		parser: /(?:^|&)([^&=]*)=?([^&]*)/g
	},
	parser: {
		strict: /^(?:([^:\/?#]+):)?(?:\/\/((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?))?((((?:[^?#\/]*\/)*)([^?#]*))(?:\?([^#]*))?(?:#(.*))?)/,
		loose:  /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/
	}
};


window.addEventListener('DOMContentLoaded', (event) => { //--> when the page is ready, we ask it for authorization info
  window.parent.postMessage("||--caiwebchatEvent--||" + JSON.stringify({ action: 'init' }), '*');
  window.removeEventListener('DOMContentLoaded', () => null);
});

window.addEventListener('message', async (event) => { //--> we wait to receive credentials 
  event.preventDefault();
  if (!event.data || (typeof event.data !== 'string')) return;  

  if (!event.data.startsWith("||--caiwebchatEvent--||")) return;
  
  let data = JSON.parse(event.data.slice(23));

  if (!data || !data.textopiaKey || !document.referrer) return; //--> these are required fields
  
  if (data && data.action === "init") {

    //--> validate document.referrer && textopiaKey again our DB
    let myKey = data.textopiaKey;
    let myDomain = document.referrer;
    if (myDomain) myDomain = parseUri(myDomain).host;

    const a = require('axios');
    
    let reqObj = {textopiaKey: myKey, domain: myDomain}    

    let httpObj = {
      url: parseConfig.SERVER_URL + "functions/checkWeb2TxtAuth",
      method: 'post',
      headers: {"X-Parse-Application-Id": parseConfig.APPLICATION_ID, "X-Parse-REST-API-Key": parseConfig.REST_API_KEY, "Content-Type": "application/json"},
      data: reqObj,
    }

    let res = await a(httpObj);
    let tenant = res.data && res.data.result;

    if (tenant) { //--> found authorization, so go ahead and render the widget and send the data packet to it for self-config
      ReactDOM.render (
        <React.StrictMode>
          <App data={tenant} />
        </React.StrictMode>,
        document.getElementById('root')
      )  
    } else return null; //--> could not auth, so just dont render anything

    
  }
});